import {NAVIGATION_ROUTE} from '@wix/events-types'
import {ComponentRef, EditorSDK, IGetDataByAppDef} from '@wix/platform-editor-sdk'
import {EVENTS_APP_DEF_ID} from '../constants'

export type OpenSettingsParams = Parameters<EditorSDK['editor']['openComponentPanel']>[1]
export type ObligatorySettingsParams = {
  url: string
  componentRef: ComponentRef
}

const baseSettingsPanelOptions: Partial<OpenSettingsParams> = {
  width: 404,
  height: 583,
  type: 'settings',
}

export const SdkWrapper = (editorSdk: EditorSDK, token: string) => {
  const wrapper = {
    openSettings: (params: OpenSettingsParams & ObligatorySettingsParams, options?: {preloader?: boolean}) => {
      const onOpen = (panelToken: string) => {
        if (options.preloader && panelToken) {
          editorSdk.editor.showPanelPreloader(panelToken)
        }
      }

      editorSdk.editor.openComponentPanel(
        token,
        {
          ...baseSettingsPanelOptions,
          ...params,
        },
        onOpen,
      )
    },

    getAppComponents: async (appDefId = EVENTS_APP_DEF_ID) => {
      const appData = await wrapper.getAppData(appDefId)

      return editorSdk.tpa.app.getAllCompsByApplicationId(token, appData.applicationId)
    },

    isCompRefOfWidgetType: async (compRef: ComponentRef, widgetId: string) => {
      const components = await wrapper.getAppComponents()

      return components.some(({id, widgetId: componentWidgetId}) => id === compRef.id && componentWidgetId === widgetId)
    },

    setComponentData: async (componentRef: ComponentRef, newData: Record<string, any>) => {
      const appData = await editorSdk.document.controllers.getData(token, {
        controllerRef: componentRef,
      })
      const componentData = appData?.config?.publicData?.COMPONENT ?? {}

      return editorSdk.document.controllers.saveConfiguration(token, {
        config: {
          ...componentData,
          ...newData,
        },
        controllerRef: componentRef,
        scope: 'COMPONENT',
      })
    },

    getAppData: async (appDefId = EVENTS_APP_DEF_ID): Promise<AppData> => {
      return editorSdk.document.tpa.app.getDataByAppDefId(token, appDefId)
    },

    getSettingsUrl: async (widgetDefId: string, appDefId = EVENTS_APP_DEF_ID) => {
      const appData = await wrapper.getAppData(appDefId)
      const widgetSettings = appData?.widgets?.[widgetDefId]?.settings

      if (widgetSettings) {
        return widgetSettings.urlV2 || widgetSettings.url
      }
    },

    navigateToEventsPage: async (tpaPageId: string) => {
      const pages = await editorSdk.document.pages.getApplicationPages(token)
      const detailsPage = pages.find(pageData => pageData.tpaPageId === tpaPageId)

      await editorSdk.pages.navigateTo(token, {
        // @ts-expect-error
        pageRef: {
          id: detailsPage.id,
        },
      })
    },

    navigateWithinDetailsPage: async (route: NAVIGATION_ROUTE) => {
      const pageRef = await editorSdk.document.pages.getCurrent(token)
      const controllersInPage = await editorSdk.document.controllers.listControllers(token, {
        pageRef,
        includeTPAWidget: true,
      })
      const controller = controllersInPage.find(c => c.controllerRef.id.includes('TPA'))

      editorSdk.document.controllers.saveConfiguration(token, {
        config: {___settingsEvent: {event: 'navigate', payload: {id: +new Date(), value: route}}},
        controllerRef: controller.controllerRef,
      })
    },

    onDashboardClose: async () => {
      await editorSdk.editor.routers.refresh(token)
      await editorSdk.tpa.app.refreshApp(token)
    },
  }

  return wrapper
}

type AppData = IGetDataByAppDef & {
  widgets?: {
    [key: string]: {settings?: {url?: string; urlV2?: string}}
  }
}
